import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import LoginContainer from "../pages/login/container";
import LandingContainer from "../pages/landing/container";
import { AuthGuard, DashGuard } from "./modules";
import makeApolloClient from "../lib/apollo";
import { ApolloProvider } from "@apollo/react-hooks";
import OtpContainer from "../pages/otp/container";
import ProfileContainer from "../pages/profile/container";

export default function App() {
  const [client, setClient] = useState(null);

  const initClient = async () => {
    let client
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN)
    if (token) {
      client = makeApolloClient(token);
    } else {
      client = makeApolloClient('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3ZmRhMWIwMy1lNTk2LTRiYmQtOTdhOS04MWEwYzc3NWIzN2UiLCJpYXQiOjE1OTg0NjQ5MDksImV4cCI6MTkxMzk5NzcwOSwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbIndlc2Nhbl91c2VyIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Indlc2Nhbl91c2VyIiwieC1oYXN1cmEtdXNlci1pZCI6IjdmZGExYjAzLWU1OTYtNGJiZC05N2E5LTgxYTBjNzc1YjM3ZSJ9fQ.vBtpj7oyvzzUCA_HKwyjVc1FqzI3q0vwRlNrIRO78BI');
    }
    setClient(client);
  };

  useEffect(() => {
    initClient();
  }, []);

  if (!client) return null

  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <AuthGuard exact path="/" component={LoginContainer}/>
          <AuthGuard exact path="/register" component={LandingContainer}/>
          <DashGuard exact path="/otp" component={OtpContainer}/>
          <DashGuard exact path="/profile" component={ProfileContainer}/>
        </Switch>
      </ApolloProvider>
    </Router>
  );
}