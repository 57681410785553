import React from "react";
import { PageContext } from "../../lib/context";
import Landingpage from ".";
import { Form, notification } from "antd";
import { useState } from "react";
import { register, sendOTP } from "../../lib/service";

const LandingContainer = (props) => {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState(null);
  const [checkTerms, setCheckTerms] = useState(false);

  const handleRegister = async (data) => {
    console.log(data);
    setErrors(null)
    if (!checkTerms) {
      setErrors('Must agree to WeScan terms and conditions.')
    } else {
      data['mobile_number'] = '63'+ data.mobile_number.substr(1)
      let res = await register(data)
      let token = res?.data?.data?.wescan_user_register?.token
      if (token) {
        console.log(token)
        localStorage.setItem(process.env.REACT_APP_LS_TOKEN, token)
        await sendOTP(data.mobile_number)
        window.location.href = '/otp'
      } else {
        notification.error({
          message: 'Mobile number is already taken',
        })
      }
    }
  };

  const pageValues = {
    form,
    handleRegister,
    checkTerms,
    setCheckTerms,
    errors
  };
  return (
    <PageContext.Provider value={pageValues}>
      <Landingpage />
    </PageContext.Provider>
  );
};

export default LandingContainer;
