import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, KeyOutlined, PhoneOutlined, HomeOutlined } from "@ant-design/icons";
import { RULES } from "../../lib/rules";
import { useContext } from "react";
import { PageContext } from "../../lib/context";

const Landingpage = (props) => {
  const {
    form,
    handleRegister,
    checkTerms,
    setCheckTerms,
    errors,
  } = useContext(PageContext);

  return (
    <div className="mt-10 flex flex-col items-center">
      <div>
        <img
          src={require("../../assets/wescan-wide.png")}
          className="h-20 rounded-lg"
        />
      </div>
      <div className="flex flex-col w-full lg:w-3/12 items-center mt-5">
        <div className="w-9/12 lg:w-full">
          <Form form={form} onFinish={handleRegister}>
            <Form.Item
              name="mobile_number"
              rules={RULES.REQUIRED("Mobile number")}
            >
              <Input
                maxLength={11}
                placeholder="Mobile number (09061234567)"
                prefix={<PhoneOutlined className="wescan-green" />}
              />
            </Form.Item>
            <Form.Item name="first_name" rules={RULES.REQUIRED("First name")}>
              <Input
                placeholder="First name"
                prefix={<UserOutlined className="wescan-green" />}
              />
            </Form.Item>
            <Form.Item name="last_name" rules={RULES.REQUIRED("Last name")}>
              <Input
                placeholder="Last name"
                prefix={<UserOutlined className="wescan-green" />}
              />
            </Form.Item>
            <Form.Item name="address">
              <Input
                placeholder="Address"
                prefix={<HomeOutlined className="wescan-green" />}
              />
            </Form.Item>
            <Form.Item name="email">
              <Input
                placeholder="Email (optional)"
                prefix={<UserOutlined className="wescan-green" />}
              />
            </Form.Item>
            <Form.Item name="password" rules={RULES.REQUIRED("Password")}>
              <Input.Password
                placeholder="Password"
                prefix={<KeyOutlined className="wescan-green" />}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={checkTerms}
                onChange={(e) => setCheckTerms(e.target.checked)}
              >
                By proceeding, you confirm that you are 18 years old or over,
                and that you have read, understood, accepted, and consented to
                the Terms and Conditions and Privacy Policy of WeScan.
              </Checkbox>
            </Form.Item>
            <p className="text-red-500">{errors !== null && errors}</p>
            <Button
              onClick={() => form.submit()}
              type="primary"
              className="wescan-green-button"
              size="large"
            >
              Register
            </Button>
            <div className="mt-5">
              <a href="/" className="text-blue-400">
                Already have an account?
              </a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
