import gql from 'graphql-tag'

export const USER = gql`
query MySubscription {
  wescan_users {
    otp
    mobile_number
    last_name
    first_name
    id
    verified
    address
    code
  }
}
`