
export const RULES = {
  REQUIRED: (name) => {
    return [
      {
        required: true,
        message: `${name} is required`
      }
    ]
  }
}