import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Button, Spin, Switch } from "antd";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import Modal from "antd/lib/modal/Modal";
import QrReader from "react-qr-reader";
import { UserOutlined, HomeOutlined, LogoutOutlined } from "@ant-design/icons";
import QRCode from "react-qr-code";

const Profilepage = (props) => {
  const {
    data,
    loading,
    visible,
    setVisible,
    step,
    setStep,
    signs,
    setSigns,
    symptoms,
    setSymptoms,
    contact,
    setContact,
    handleProceed,
    showQRCode,
    handleScan,
    handleScanError,
    showScanner,
    setShowScanner,
    showCompanyQR,
    companyDetais,
  } = useContext(PageContext);

  if (loading) return <Spin />;

  return (
    <div className="flex items-center w-full flex-col justify-center">
      <div className="lg:w-4/12 mt-10 flex flex-col px-10 w-full self-center">
      <div className="self-center">
        <img
          src={require("../../assets/wescan-square.png")}
          className="h-20"
        />
      </div>
        <p className="text-left mt-5">
          Welcome to your own profile. Answer the health question truthfully to
          obtain a QR Code to enter the establishments. This ensure the health
          and welfare of our clients and employees.
        </p>
        <div className="flex flex-row wescan-green text-base mt-2">
          <UserOutlined className="mt-1 wescan-green mr-1" />
          {data?.wescan_users?.[0]?.first_name?.toUpperCase()}{" "}
          {data?.wescan_users?.[0]?.last_name?.toUpperCase()}
        </div>
        <div className="flex flex-row wescan-green text-base mt-2">
          <HomeOutlined className="mt-1 wescan-green mr-1" />
          {data?.wescan_users?.[0]?.address?.toUpperCase()}
        </div>
        <div>
          <p className="flex flex-row cursor-pointer wescan-green text-left text-base" onClick={()=>{
            localStorage.removeItem(process.env.REACT_APP_LS_TOKEN)
            window.location.href = "/"
          }}>
            <LogoutOutlined className="mt-1 wescan-green mr-1" />
            Logout</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="mt-10 flex flex-col">
            {showQRCode && (
              <div className="flex flex-col items-center">
                <QRCode value={data?.wescan_users?.[0]?.code} />
                <p className="text-2xl font-bold">
                  {data?.wescan_users?.[0]?.code}
                </p>
                <p>This is your QR Code. Show this to enter establishments.</p>
              </div>
            )}
            {!showQRCode && (
              <Button
                type="primary"
                size="large"
                className="wescan-green-button mt-10 w-64"
                onClick={() => {
                  setVisible(true);
                  setStep(1);
                }}
              >
                SHOW YOUR QR CODE
              </Button>
            )}
            {showCompanyQR && (
              <div className="mt-10 flex flex-col items-center">
                <QRCode value={companyDetais?.code} />
                <p className="text-2xl font-bold">{companyDetais?.code}</p>
                <p>Company: {companyDetais?.company?.company_name}</p>
                <p>Address: {companyDetais?.company?.address}</p>
              </div>
            )}
            <Button
              type="primary"
              size="large"
              className="wescan-green-button mt-10 w-64 mb-20"
              onClick={() => {
                setVisible(true);
                setShowScanner(true);
                setStep(1);
              }}
            >
              SCAN COMPANY QR CODE
            </Button>
          </div>
        </div>

        
      </div>
      <Modal
          visible={visible}
          onCancel={() => {
            setVisible(false);
            setShowScanner(false);
            setStep(0);
          }}
          okText="Proceed"
          onOk={handleProceed}
        >
          {step === 1 && (
            <div>
              <p className="text-xl font-bold wescan-green">Health Questions</p>
              <p>
                Have you had any signs or symptoms of a fever in the past 24
                hours such as chills, sweats, felt "feverish" or had a
                temperature that is elevated for you/37.5°C or greater?
              </p>
              <Switch
                checkedChildren="YES"
                unCheckedChildren="NO"
                checked={signs}
                onChange={(e) => setSigns(e)}
              />
              <p className="mt-5">Do you have any of the following symptoms?</p>
              <ol className="ml-5">
                <li>Cough</li>
                <li>Shortness of Breath or Chest Tightness</li>
                <li>Sore Throat</li>
                <li>Nasal Congestion/Runny Nose</li>
                <li>Myalgia (Body Aches)</li>
                <li>Loss of Taste and/or Smell</li>
                <li>Diarrhea</li>
                <li>Nausea</li>
                <li>Vomiting</li>
                <li>Fever/Chills/Sweats</li>
              </ol>
              <Switch
                checkedChildren="YES"
                unCheckedChildren="NO"
                checked={symptoms}
                onChange={(e) => setSymptoms(e)}
              />

              <p className="mt-5">
                Have you been in contact within the last 14 days with someone
                with a confirmed diagnosis of COVID-19 or traveled to another
                country?
              </p>
              <Switch
                checkedChildren="YES"
                unCheckedChildren="NO"
                checked={contact}
                onChange={(e) => setContact(e)}
              />
            </div>
          )}
          {step === 2 && showScanner && (
            <div>
              <p className="text-xl font-bold wescan-green">Scan Company</p>
              <QrReader
                delay={300}
                onError={handleScanError}
                onScan={handleScan}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </Modal>
    </div>
  );
};

export default Profilepage;
