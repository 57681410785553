import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Button, Spin } from "antd";
import { useMemo } from "react";
import { useSubscription, useMutation, useQuery } from "@apollo/react-hooks";
import { USER } from "../../lib/subscriptions";
import { UPDATE_USER } from "../../lib/mutations";
import { sendOTP } from "../../lib/service";

const Otppage = (props) => {
  const [otp, setOtp] = useState("");

  const { data, loading, refetch } = useQuery(USER, {
    pollInterval: 10000,
    fetchPolicy: 'net'
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      refetch()
    }
  })

  const handleChange = (otp) => {
    setOtp(otp);
  };

  useMemo(()=>{
    if (data) {
      if (data?.wescan_users?.[0]?.verified) {
        window.location.href = '/profile'
      }
      async function update() {
        await updateUser({
          variables: {
            id: data?.wescan_users?.[0]?.id
          }
        })
      }
      if (otp.length === 4) {
        if (otp == data?.wescan_users?.[0]?.otp) {
          console.log('updating')
          update()
        }
      }
    }
  },[data])

  useMemo(() => {
    async function update() {
      await updateUser({
        variables: {
          id: data?.wescan_users?.[0]?.id
        }
      })
    }
    if (otp.length === 4) {
      if (otp == data?.wescan_users?.[0]?.otp) {
        update()
      }
    }
  }, [otp]);

  return (
    <div className="mt-20 flex flex-col px-10">
      {loading && (
        <>
          <p className="wescan-green text-4xl font-bold">Welcome to WeScan</p>
          <Spin />
        </>
      )}
      {!loading && (
        <>
          <p className="wescan-green text-4xl font-bold">WeScan OTP</p>
          <p className="text-xl">
            Verify your account. Enter the OTP code we've sent to your mobile
            number.
          </p>
          <div className="flex flex-col items-center">
            <div className="mt-20">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span className="text-green-600 w-5" />}
                inputStyle={{
                  height: 60,
                  width: 60,
                  border: "1px solid #2BBB94",
                  borderRadius: 10,
                  fontSize: 30,
                }}
              />
              <Button
                type="primary"
                size="large"
                className="wescan-green-button mt-10"
                onClick={() => {
                  window.location.href = "/";
                  localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Otppage;
