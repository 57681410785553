import React, { useState } from "react";
import { PageContext } from "../../lib/context";
import {
  useMutation,
  useSubscription,
  useQuery,
  useLazyQuery,
  useApolloClient
} from "@apollo/react-hooks";
import { USER } from "../../lib/subscriptions";
import Profilepage from ".";
import { notification } from "antd";
import { SCAN_COMPANY } from "../../lib/queries";
import { useMemo } from "react";

const ProfileContainer = (props) => {
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [signs, setSigns] = useState(true);
  const [symptoms, setSymptoms] = useState(true);
  const [contact, setContact] = useState(true);
  const [showQRCode, setShowQrCode] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [showCompanyQR, setShowCompanyQR] = useState(false);
  const [companyDetais, setCompanyDetails] = useState(null);
  const client = useApolloClient()

  const { data, loading } = useQuery(USER, {
    fetchPolicy: "network-only",
  });

  const handleProceed = () => {
    if (signs || symptoms || contact) {
      notification.error({
        message: "WeScan",
        description: "Sorry you can not enter at this moment.",
      });
    } else {
      if (step === 1 && !showScanner) {
        setShowQrCode(true);
      }
      if (showScanner) {
        setStep(2);
      } else {
        setVisible(false);
        setStep(0);
      }
    }
  };

  const handleScan = async (code) => {
    if (!code) return
    setShowCompanyQR(false);
    setShowScanner(false);
    const res = await client.query({
      query: SCAN_COMPANY,
      fetchPolicy: 'network-only',
      variables: {
        code
      }
    })
    let scanner = res?.data?.wescan_company_scanners;
    if (scanner?.length) {
      console.log("scan", scanner);
      setCompanyDetails({ ...scanner?.[0] });
      setShowCompanyQR(true);
    }
    setVisible(false);
  };

  const handleScanError = async (err) => {
    console.log("scan err", err);
    setShowCompanyQR(false);
  };

  // useMemo(() => {
  //   if (company_data) {
  //     let scanner = company_data?.wescan_company_scanners;
  //     if (scanner?.length) {
  //       console.log("scan", scanner);
  //       setCompanyDetails({ ...scanner?.[0] });
  //       setShowCompanyQR(true);
  //     }
  //     setVisible(false);
  //   }
  // }, [company_data]);

  const contextValues = {
    data,
    loading,
    visible,
    setVisible,
    step,
    setStep,
    signs,
    setSigns,
    symptoms,
    setSymptoms,
    contact,
    setContact,
    handleProceed,
    showQRCode,
    setShowQrCode,
    handleScan,
    handleScanError,
    showScanner,
    setShowScanner,
    showCompanyQR,
    companyDetais,
  };
  return (
    <PageContext.Provider value={contextValues}>
      <Profilepage />
    </PageContext.Provider>
  );
};

export default ProfileContainer;
