import React from 'react'
import gql from 'graphql-tag'
import {print} from 'graphql'
import axios from 'axios'

export const register = async (data) => {
  return await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(gql`
        mutation($objects: WeScanUserRegisterInput) {
          wescan_user_register(object: $objects) {
            token
          }
        }
			`),
			variables: {
        objects: {
          ...data
        }
			},
		})
		.then(async (res) => {
			return res
		})
		.catch((err) => {
			console.log('errs ' + err) 
			return err
		})
}

export const login = async (data) => {
  return await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(gql`
				mutation($objects: WeScanUserLoginInput) {
					wescan_user_login(object: $objects) {
						token
					}
				}
			`),
			variables: {
        objects: {
          ...data
        }
			},
		})
		.then(async (res) => {
			return res
		})
		.catch((err) => {
			console.log('errs ' + err) 
			return err
		})
}

export const sendOTP = async (mobile_number) => {
	return await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(gql`
				mutation($mobile_number: String) {
					wescan_otp_generator(object: {
						mobile_number: $mobile_number,
						type: "user"
					}) {
						code
						message
					}
				}
			`),
			variables: {
        mobile_number
			},
		})
		.then(async (res) => {
			return res
		})
		.catch((err) => {
			console.log('errs ' + err) 
			return err
		})
}