import React, { useState } from 'react'
import { PageContext } from '../../lib/context'
import Loginpage from '.'
import {Form, notification} from 'antd'
import {login, sendOTP} from '../../lib/service'

const LoginContainer = (props) => {
  const [form] = Form.useForm()
  const [errors, setErrors] = useState(null);
  const [checkTerms, setCheckTerms] = useState(false);

  const handleLogin = async (data) => {
    console.log(data);
    setErrors(null)
    if (!checkTerms) {
      setErrors('Must agree to WeScan terms and conditions.')
    } else {
      data['mobile_number'] = '63'+ data.mobile_number.substr(1)
      data['type'] = 'user'
      let res = await login(data)
      let token = res?.data?.data?.wescan_user_login?.token
      if (token) {
        console.log(token)
        localStorage.setItem(process.env.REACT_APP_LS_TOKEN, token)
        await sendOTP(data.mobile_number)
        window.location.href = '/otp'
      } else {
        notification.error({
          message: 'Invalid Mobile number or Password',
        })
      }
    }
  }

  const contextValues = {
    form,
    handleLogin,
    checkTerms, 
    setCheckTerms,
    errors,
    setErrors
  }

  return (
    <PageContext.Provider value={contextValues}>
      <Loginpage/>
    </PageContext.Provider>
  )
}

export default LoginContainer