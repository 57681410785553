import React from 'react'
import { PageContext } from '../../lib/context'
import Otppage from '.'
import {useMutation, useSubscription, useQuery} from '@apollo/react-hooks'
import { USER } from '../../lib/subscriptions'

const OtpContainer = (props) => {
  const contextValues = {

  }
  return (
    <PageContext.Provider value={contextValues}>
      <Otppage/>
    </PageContext.Provider>
  )
}

export default OtpContainer