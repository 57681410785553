import React from "react";
import { Route, Redirect } from "react-router-dom";

export const AuthGuard = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!localStorage.getItem(process.env.REACT_APP_LS_TOKEN) ? (
				<Component {...props} />
			) : (
				<Redirect to="/otp" />
			)
		}
	/>
);

export const DashGuard = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				localStorage.getItem(process.env.REACT_APP_LS_TOKEN) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
			}
		/>
	);
};
