import gql from 'graphql-tag'

export const SCAN_COMPANY = gql`
query MyQuery($code: String) {
  wescan_company_scanners(where: {code: {_eq: $code}}) {
    code
    name
    company {
      address
      company_name
    }
  }
}
`